import React from 'react';
import VideoPlayer from './VideoPlayer';

export default class Banner extends React.Component {

    render() {
        return (
            <div className="banner-wrapper">
                <div className={'banner-new banner-'+process.env.REACT_APP_WHICH_APP}>

                    <div class="ford">
                        <div id="carouselExample" class="carousel slide">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src="/images/MEA_Accessories_asset.jpg" class="d-block w-100" alt=""/>
                                </div>
                                <div class="carousel-item text-center">
                                    <VideoPlayer />
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="content">
                                <h1>
                                    <span language="en">{process.env.REACT_APP_WHICH_APP} Accessories</span>
                                    <span language="ar">ملحقات {process.env.REACT_APP_WHICH_APP}</span>
                                </h1>
                                <h3>
                                    <span language="ar">هنا تكتمل أناقة سيارتك</span>
                                    <span language="en">Accessorize in Style</span>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="lincoln">
                        <div className="container">
                            <div className="content">
                                <h1>
                                    <span language="en">{process.env.REACT_APP_WHICH_APP} Accessories</span>
                                    <span language="ar">ملحقات {process.env.REACT_APP_WHICH_APP}</span>
                                </h1>
                                <h3>
                                    <span language="ar">هنا تكتمل أناقة سيارتك</span>
                                    <span language="en">Accessorize in Style</span>
                                </h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}