import React, { useRef, useEffect } from 'react';

const VideoPlayer = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const playVideo = async () => {
      try {
        if (videoRef.current) {
          await videoRef.current.play();
        }
      } catch (err) {
        console.log('Autoplay failed:', err);
      }
    };

    playVideo();
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <video 
        ref={videoRef}
        className="img-fluid"
        style={{ objectFit: 'contain' }}
        muted
        playsInline
        loop
      >
        <source src="/videos/1257x759_2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;